import { apiGraphQL, apiRPC } from '@/constants/server/routes';
import { domain } from '@/constants/web';

export function packURL(json: any) {
  return encodeURIComponent(btoa(JSON.stringify(json)));
}

export function unpackURL(json: any) {
  return JSON.parse(atob(decodeURIComponent(json)));
}

export function packAddress(address: string): string {
  return packURL([address]);
}

export function unpackAddress(encodedAddress: string): string {
  return unpackURL(encodedAddress)[0];
}

export function getBaseUrl() {
  if (typeof window !== 'undefined') return '';
  if (process.env.NODE_ENV === 'production') return domain;
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

export function getHostname() {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000';
  }

  return getBaseUrl() || domain;
}

export function createURL(pathname = '') {
  return getHostname() + pathname;
}

export function getRPCUrl() {
  return [getBaseUrl(), apiRPC].join('');
}

export function getGraphqlURL() {
  return [getHostname(), apiGraphQL].join('');
}

export function createUrlArgs(
  args: Record<string, string | number | boolean | undefined>,
) {
  return Object.entries(args)
    .filter(([, value]) => typeof value === 'boolean' || !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}
