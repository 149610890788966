import { TRPCClientError } from '@trpc/client';
import { Callout, Text } from '@radix-ui/themes';

import type { RPCError } from '@/types/rpc';
import { FileWarningIcon } from 'lucide-react';

export const i18n = {
  en: {
    title: 'Error',
    subtitle: 'Oops! Something went wrong.',
  },
};

export default function ErrorView({ error }: { error?: Error | RPCError }) {
  return (
    <Callout.Root color="red">
      <Callout.Icon>
        <FileWarningIcon />
      </Callout.Icon>

      <Callout.Text>
        <Text as="span" weight="bold">
          {i18n.en.title}
        </Text>{' '}
        -{' '}
        <Text as="span" weight="medium">
          {i18n.en.subtitle}
        </Text>
        {error instanceof TRPCClientError ? (
          <pre>
            <code>{error.data.code}</code> {error.message}
          </pre>
        ) : null}
      </Callout.Text>
    </Callout.Root>
  );
}
