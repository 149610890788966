'use client';

import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import { loggerLink, httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';

import type { RPCRouter } from '@/types/rpc';
import { getRPCUrl } from '@/lib/utils/url';
import { transformer } from '@/lib/utils/shared';

export const api = createTRPCReact<RPCRouter>();

export const createQueryClient = (() => {
  let client: QueryClient | undefined;

  return () => {
    if (!client) {
      client = new QueryClient({
        defaultOptions: {},
      });
    }

    return client;
  };
})();

export const createTRPCClient = (() => {
  let client: ReturnType<typeof api.createClient>;

  return (url = getRPCUrl()) => {
    if (!client) {
      client = api.createClient({
        transformer,
        links: [
          loggerLink({
            enabled: (op) =>
              process.env.NODE_ENV === 'development' ||
              (op.direction === 'down' && op.result instanceof Error),
          }),
          httpBatchLink({
            url,
          }),
        ],
      });
    }

    return client;
  };
})();

export function TRPCReactProvider(props: PropsWithChildren) {
  const [queryClient] = useState(createQueryClient);
  const [trpcClient] = useState(createTRPCClient);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryStreamedHydration>
        <api.Provider client={trpcClient} queryClient={queryClient}>
          {props.children}
        </api.Provider>
      </ReactQueryStreamedHydration>
    </QueryClientProvider>
  );
}
