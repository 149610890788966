import type { PropsWithChildren } from 'react';
import type {
  NavigationMenuProps,
  NavigationMenuListProps,
  NavigationMenuItemProps,
  NavigationMenuContentProps,
  NavigationMenuTriggerProps,
  NavigationMenuLinkProps,
  NavigationMenuViewportProps,
} from '@radix-ui/react-navigation-menu';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Card } from '@radix-ui/themes';
import Link, { LinkProps } from 'next/link';

export interface NavigationTriggerProps
  extends PropsWithChildren<NavigationMenuTriggerProps> {
  asChild?: boolean;
}

export function NavigationTrigger({
  children,
  ...props
}: NavigationTriggerProps) {
  return <NavigationMenu.Trigger {...props}>{children}</NavigationMenu.Trigger>;
}

export interface NavigationLinkProps
  extends Omit<NavigationMenuLinkProps, 'href'>,
    Omit<LinkProps<any>, 'onSelect'> {}

export function NavigationLink({
  children,
  href,
  ...props
}: NavigationLinkProps) {
  return (
    /* @ts-ignore */
    <NavigationMenu.Link asChild {...props}>
      <Link href={href}>{children}</Link>
    </NavigationMenu.Link>
  );
}

export interface NavigationContentProps
  extends PropsWithChildren<NavigationMenuContentProps> {}

export function NavigationContent({
  children,
  ...props
}: NavigationContentProps) {
  return (
    <NavigationMenu.Content
      {...props}
      asChild
      // className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight"
    >
      <Card className="backdrop-blur-md">{children}</Card>
    </NavigationMenu.Content>
  );
}

export interface NavigationItemProps
  extends PropsWithChildren<NavigationMenuItemProps> {}

export function NavigationItem({ children, ...props }: NavigationItemProps) {
  return <NavigationMenu.Item {...props}>{children}</NavigationMenu.Item>;
}

export interface NavigationListProps
  extends PropsWithChildren<NavigationMenuListProps> {
  className?: string;
}

export function NavigationList({ children, ...props }: NavigationListProps) {
  return (
    <NavigationMenu.List
      {...props}
      // className="center shadow-blackA4 m-0 flex list-none rounded-[6px] bg-white p-1 shadow-[0_2px_10px] "
    >
      {children}
    </NavigationMenu.List>
  );
}

export interface NavigationViewportProps extends NavigationMenuViewportProps {}

export function NavigationViewport(props: NavigationViewportProps) {
  return (
    <NavigationMenu.Viewport
      className="absolute right-0 top-0 z-[10] -me-2 mt-10"
      // className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[6px] bg-white transition-[width,_height] duration-300"
      {...props}
    />
  );
  // return (
  //   <div className="perspective-[2000px] absolute left-0 top-full flex w-full justify-center">
  //     <NavigationMenu.Viewport
  //       // className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[6px] bg-white transition-[width,_height] duration-300"
  //       {...props}
  //     />
  //   </div>
  // );
}

export interface NavigationProps
  extends PropsWithChildren<NavigationMenuProps> {
  title?: string;
}

export function Navigation({ children, ...props }: NavigationProps) {
  return (
    <NavigationMenu.Root {...props} className="relative">
      {children}
    </NavigationMenu.Root>
  );
}
