import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';

export type AppColorSchemeType = 'system' | 'light' | 'dark';
export type AppScaleType = 90 | 95 | 100 | 105 | 110;

export interface AppState {
  offline: boolean;
  theme: string | null;
  themeAccent: string;
  themeGray: string;
  colorScheme: AppColorSchemeType;
  scale: AppScaleType;
  hiContrast: boolean;
  setTheme: (theme: string) => void;
  setColorScheme: (theme: AppColorSchemeType) => void;
  setScale: (scale: AppScaleType) => void;
  toggleHiContrast: () => void;
}

export const defaultState: Partial<AppState> = {
  offline: false,
  theme: null,
  themeAccent: 'grass',
  themeGray: 'sage',
  colorScheme: 'system',
  scale: 100,
  hiContrast: false,
};

export const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      offline: false,
      theme: null,
      themeAccent: 'grass',
      themeGray: 'sage',
      colorScheme: 'system',
      scale: 100,
      hiContrast: false,
      setTheme: (theme: string) => set({ theme }),
      setColorScheme: (colorScheme: AppState['colorScheme']) =>
        set({ colorScheme }),
      setScale: (scale: AppState['scale']) => set({ scale }),
      toggleHiContrast: () =>
        set((state) => ({ hiContrast: !state.hiContrast })),
      setOffline: (offline: boolean) => set({ offline }),
    }),
    {
      name: 'app-storage',
    },
  ),
);

export function useThemeManager() {
  return useAppStore(
    useShallow((state) => ({
      theme: state.theme,
      themeAccent: state.theme,
      themeGray: state.theme,
      setTheme: state.setTheme,
      colorScheme: state.colorScheme,
      setColorScheme: state.setColorScheme,
      scale: state.scale,
      setScale: state.setScale,
      hiContrast: state.hiContrast,
      toggleHiContrast: state.toggleHiContrast,
    })),
  );
}
export function useThemeState() {
  return useAppStore(
    useShallow((state) => ({
      theme: state.theme,
      themeAccent: state.theme,
      themeGray: state.theme,
      colorScheme: state.colorScheme,
      scale: state.scale,
      hiContrast: state.hiContrast,
    })),
  );
}
