export type ColorScheme = 'light' | 'dark' | 'system';

export const disableAnimation = () => {
  const css = document.createElement('style');
  css.appendChild(
    document.createTextNode(
      `*{-webkit-transition:none!important;-moz-transition:none!important;-o-transition:none!important;-ms-transition:none!important;transition:none!important}`,
    ),
  );
  document.head.appendChild(css);
  // Force restyle
  window.getComputedStyle(document.body);
  // Wait for next tick before removing
  setTimeout(() => {
    document.head.removeChild(css);
  }, 1);
};

export const MEDIA = '(prefers-color-scheme: dark)';
export const matchMedia = () =>
  (typeof window !== 'undefined' && window.matchMedia(MEDIA)) || {
    matches: false,
  };
export const resolveColorScheme = (scheme?: ColorScheme) => {
  if (!scheme || scheme === 'system') {
    const { matches } = matchMedia();
    scheme = matches ? 'dark' : 'light';
  }
  return scheme;
};

export const commitColorScheme = (scheme?: ColorScheme) => {
  scheme = resolveColorScheme(scheme);
  document.documentElement.classList.remove('light', 'dark');
  document.documentElement.classList.add(scheme);
  document.documentElement.style.colorScheme = scheme;
  disableAnimation();
};
