'use client';

import {
  forwardRef,
  useImperativeHandle,
  useContext,
  createContext,
  type PropsWithChildren,
} from 'react';
import { Card } from '@radix-ui/themes';
import {
  Provider,
  Viewport,
  Root,
  Title,
  Description,
  Action,
  Close,
  ToastProviderProps,
  ToastViewportProps,
} from '@radix-ui/react-toast';

import { useToaster as useToasterState } from '@/hooks/useToaster';

export interface ToastProps {
  id?: string;
  open?: boolean;
  title?: PropsWithChildren['children'] | string;
  description?: PropsWithChildren['children'] | string;
  close?: PropsWithChildren['children'] | string;
  actions?: {
    action: PropsWithChildren['children'] | string;
    altText: string;
  }[];
}

export const ToasterContext = createContext<
  ReturnType<typeof useToasterState<ToastProps>>
>({
  toasts: [],
  addToast: (props: ToastProps) => {},
  removeToast: (id: string) => {},
  updateToast: (props: ToastProps) => {},
});

export function useToaster() {
  return useContext(ToasterContext);
}

export function Toast({
  children,
  id,
  title,
  description,
  close,
  actions = [],
  ...props
}: PropsWithChildren<ToastProps>) {
  const { removeToast } = useToaster();
  return (
    <Root
      {...props}
      onOpenChange={(open: boolean) => {
        return removeToast(id!);
      }}
      className="bg-[--background]shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] grid grid-cols-[auto_max-content] items-center gap-x-[15px] rounded-md [grid-template-areas:_'title_action'_'description_action'] data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[state=closed]:animate-hide data-[state=open]:animate-slideIn data-[swipe=end]:animate-swipeOut data-[swipe=cancel]:transition-[transform_200ms_ease-out]"
    >
      <Card>
        {title && (
          <Title className="text-slate12 mb-[5px] text-[15px] font-medium [grid-area:_title]">
            {title}
          </Title>
        )}
        {description && (
          <Description className="text-slate11 m-0 text-[13px] leading-[1.3] [grid-area:_description]">
            {description}
          </Description>
        )}
        {children}
        {actions.length
          ? actions.map(({ altText, action }, index: number) => (
              <Action
                altText={altText}
                key={index}
                className="[grid-area:_action]"
              >
                {action}
              </Action>
            ))
          : null}
        {close && <Close>{close}</Close>}
      </Card>
    </Root>
  );
}

export function ToasterViewport(props: PropsWithChildren<ToastViewportProps>) {
  return (
    <Viewport
      {...props}
      className="fixed bottom-0 right-0 z-[2147483647] m-0 flex w-[390px] max-w-[100vw] list-none flex-col gap-[10px] p-[var(--viewport-padding)] outline-none [--viewport-padding:_25px]"
    />
  );
}

export const Toaster = forwardRef(function ToasterController(
  { children, ...props }: PropsWithChildren<ToastProviderProps>,
  ref,
) {
  const { toasts, addToast, removeToast, updateToast } =
    useToasterState<ToastProps>();

  useImperativeHandle(
    ref,
    () => ({
      show: (props: ToastProps) => {
        addToast(props);
      },
      addToast,
      removeToast,
      updateToast,
    }),
    [],
  );

  return (
    <Provider swipeDirection="right" {...props}>
      <ToasterContext.Provider
        value={{
          toasts,
          addToast,
          removeToast,
          updateToast,
        }}
      >
        {children}
        {toasts.map((toast: ToastProps, index) => (
          <Toast {...toast} key={index} />
        ))}
      </ToasterContext.Provider>

      <ToasterViewport />
    </Provider>
  );
});

Toaster.displayName = 'Toaster';
