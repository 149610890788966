import { useMemo, useReducer } from 'react';

const ACTION_TYPES = ['ADD_TOAST', 'REMOVE_TOAST', 'UPDATE_TOAST'] as const;

export type Toast<T = Record<string, any>> = {
  id?: string;
} & T;

export interface ToastAction<T = Record<string, any>> {
  type: (typeof ACTION_TYPES)[number];
  payload: Toast<T> | Partial<Toast<T>>;
}

function addToast<T = Record<string, any>>(toast: Toast<T>) {
  return {
    type: ACTION_TYPES[0],
    payload: (toast satisfies Toast<T>)
      ? toast
      : ({ ...toast, id: Date.now().toString() } as Toast<T>),
  };
}

function removeToast<T = Record<string, any>>(toastId: string) {
  return {
    type: ACTION_TYPES[1],
    payload: { id: toastId } as unknown as Partial<Toast<T>>,
  };
}

function updateToast<T = Record<string, any>>(toast: Toast<T>) {
  return {
    type: ACTION_TYPES[2],
    payload: toast,
  };
}

export function createActions<T = Record<string, any>>(
  dispatch: (action: ToastAction<T>) => void,
) {
  return {
    addToast: (toast: Toast<T>) => dispatch(addToast<T>(toast)),
    removeToast: (toastId: string) => dispatch(removeToast(toastId)),
    updateToast: (toast: Toast<T>) => dispatch(updateToast<T>(toast)),
  };
}

export const initialState = { toasts: [] };

export function toastReducer<T = Record<string, any>>(
  state: { toasts: Toast<T>[] } = initialState,
  action: ToastAction<T>,
) {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        toasts: [
          ...state.toasts,
          {
            ...action.payload,
          },
        ],
      };
    case 'REMOVE_TOAST':
      return {
        toasts: state.toasts.filter((toast) => toast.id !== action.payload.id),
      };
    case 'UPDATE_TOAST':
      return {
        toasts: state.toasts.map((toast) =>
          toast.id === action.payload.id
            ? { ...toast, ...action.payload }
            : toast,
        ),
      };
    default:
      return state;
  }
}

export function useToaster<T = Record<string, any>>() {
  const [{ toasts }, dispatch] = useReducer(toastReducer, initialState);

  return useMemo(
    () => ({
      toasts,
      ...createActions<T>(dispatch),
    }),
    [toasts],
  );
}
