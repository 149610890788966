import { useEffect, type PropsWithChildren } from 'react';
import { Theme } from '@radix-ui/themes';

import '@/styles/globals.css';
import { useThemeManager } from '@/hooks/app';
import { type ColorScheme, commitColorScheme } from '@/lib/utils/theme';

export function ThemeProvider({
  children,
  theme,
}: PropsWithChildren<{ theme?: ColorScheme }>) {
  const { scale = 100, colorScheme, setColorScheme } = useThemeManager();

  useEffect(() => {
    commitColorScheme(colorScheme);
  }, [colorScheme]);

  useEffect(() => {
    theme && setColorScheme(theme);
  }, [theme]);

  // TODO: add page visibility listener when system is the theme mode
  return (
    <Theme
      hasBackground={false}
      accentColor="grass"
      grayColor="sage"
      scaling={`${scale}%`}
      panelBackground="translucent"
      radius="medium"
    >
      {children}
    </Theme>
  );
}
