'use client';

import type { Route } from 'next';
import { useRouter } from 'next/navigation';
import Link from 'next/link';
import {
  Button,
  Flex,
  IconButton,
  Link as Anchor,
  Skeleton,
  Strong,
  Text,
  Separator,
  Avatar,
} from '@radix-ui/themes';
import {
  ArrowRightIcon,
  BellIcon,
  MenuIcon,
  LayoutDashboardIcon,
  User2Icon,
  InboxIcon,
} from 'lucide-react';

import type { RPCError, RPCOutputs } from '@/types/rpc';
import { api } from '@/data/client';

import {
  Navigation,
  NavigationList,
  NavigationItem,
  NavigationContent,
  NavigationTrigger,
  NavigationViewport,
  NavigationLink,
} from '@/components/radix';
import ErrorView from '@/views/components/Error';
import { cx } from 'class-variance-authority';

const i18n = {
  en: {
    menu: 'menu',
    noNotifications: 'no notifications',
    unreadNotifications: 'unread notifications',
    labels: {
      appNav: 'app navigation',
      userNav: 'user menu',
      notificationNav: 'notification menu',
    },
    links: {
      dashboard: 'Dashboard',
      notifications: 'Notifications',
      account: 'Account',
      wallet: 'Wallet',
      signIn: 'Sign in',
      signUp: 'Get started',
      providers: 'Providers',
    },
    actions: {
      viewAllNotifications: 'view all',
      signout: 'Sign out',
    },
  },
  links: {
    home: '/' as Route,
    signIn: '/signin' as Route,
    signUp: '/signup' as Route,
    providers: '/providers' as Route,
    dashboard: '/app' as Route,
    notifications: '/app/notifications' as Route,
    account: '/app/account' as Route,
    wallet: '/app/account/wallet' as Route,
  },
};

const publicLinks = [
  {
    href: i18n.links.providers,
    label: i18n.en.links.providers,
  },
];

const authLinks = [
  { href: i18n.links.account, label: i18n.en.links.account },
  { href: i18n.links.wallet, label: i18n.en.links.wallet },
];

export function PublicDesktopNavigation({ loading }: { loading?: boolean }) {
  return (
    <nav className=" hidden w-full justify-between ps-4 md:flex">
      <ul className="flex items-end gap-4 pb-1 text-lg">
        {loading ? null : (
          <li className="h-fit">
            <Anchor asChild color="grass">
              <Link href={i18n.links.providers}>
                <Text as="span" weight="medium">
                  {i18n.en.links.providers}
                </Text>
              </Link>
            </Anchor>
          </li>
        )}
      </ul>

      <div className="flex items-center justify-center gap-2">
        <Skeleton loading={loading}>
          <Button asChild variant="soft" size="3">
            <Link href={i18n.links.signIn}>
              <Strong>{i18n.en.links.signIn}</Strong>
            </Link>
          </Button>
        </Skeleton>

        <Skeleton loading={loading}>
          <Button asChild size="4">
            <Link href={i18n.links.signUp}>
              <span className="flex items-center justify-center gap-2">
                <Strong>{i18n.en.links.signUp}</Strong>
                <ArrowRightIcon />
              </span>
            </Link>
          </Button>
        </Skeleton>
      </div>
    </nav>
  );
}

export function PublicMobileMenu({ loading }: { loading?: boolean }) {
  return (
    <div className="ms-auto flex w-full justify-end md:hidden">
      <Navigation>
        <NavigationList>
          <NavigationItem>
            <Skeleton loading={loading}>
              <NavigationTrigger asChild>
                <IconButton variant="ghost" aria-label={i18n.en.menu}>
                  <MenuIcon />
                </IconButton>
              </NavigationTrigger>
            </Skeleton>
            <NavigationContent>
              <ul className="flex w-48 flex-col gap-2">
                {publicLinks.map((link) => (
                  <li key={link.href} className="flex w-full flex-col">
                    <Button asChild variant="soft" size="3">
                      <NavigationLink href={link.href} className="w-full">
                        <span className="flex items-center justify-center gap-2">
                          <Strong>{link.label}</Strong>
                        </span>
                      </NavigationLink>
                    </Button>
                  </li>
                ))}

                <li className="flex w-full flex-col">
                  <Button asChild variant="soft" size="3">
                    <NavigationLink href={i18n.links.signIn} className="w-full">
                      <span className="flex items-center justify-center gap-2">
                        <Strong>{i18n.en.links.signIn}</Strong>
                      </span>
                    </NavigationLink>
                  </Button>
                </li>

                <li className="flex w-full flex-col">
                  <Button asChild size="4">
                    <NavigationLink href={i18n.links.signUp} className="w-full">
                      <span className="flex items-center justify-center gap-2">
                        <Strong>{i18n.en.links.signUp}</Strong>
                        <ArrowRightIcon />
                      </span>
                    </NavigationLink>
                  </Button>
                </li>
              </ul>
            </NavigationContent>
          </NavigationItem>
        </NavigationList>
        <NavigationViewport />
      </Navigation>
    </div>
  );
}

export default function NavigationWidget(props: {
  loading?: boolean;
  user?: RPCOutputs['user']['me'];
}) {
  const router = useRouter();
  const { data, isLoading, isError, error, refetch } = api.user.me.useQuery(
    undefined,
    {
      enabled: !props.loading,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: !!props.user,
      initialData: props.user,
    },
  );

  const logout = api.auth.logout.useMutation({
    onSuccess: () => {
      refetch();
      router.push(i18n.links.home);
    },
  });

  if (!data) {
    if (isError) {
      return <ErrorView error={error as RPCError} />;
    }

    return (
      <>
        <PublicDesktopNavigation loading={isLoading} />
        <PublicMobileMenu loading={isLoading} />
      </>
    );
  }

  return (
    <Flex
      justify="end"
      align="center"
      className="ms-auto w-fit rounded-md"
      // className="ms-auto w-fit rounded-md border border-sage-6 dark:border-sageDark-4 dark:bg-sageDarkA-2"
      p="3"
    >
      <Navigation title={i18n.en.labels.appNav}>
        <NavigationList className="flex flex-row gap-4">
          <NavigationItem>
            <IconButton asChild variant="ghost">
              <NavigationLink asChild href={i18n.links.dashboard}>
                <span className="sr-only">{i18n.en.links.dashboard}</span>
                <LayoutDashboardIcon
                // style={{ fill: 'currentColor', stroke: 'currentColor' }}
                />
              </NavigationLink>
            </IconButton>
          </NavigationItem>

          <NavigationItem>
            <NavigationTrigger asChild>
              <IconButton
                variant="ghost"
                aria-label={i18n.en.labels.notificationNav}
              >
                <div className="relative">
                  <div className="absolute -top-1 right-0 h-full">
                    <div
                      role="status"
                      aria-label={`${data.unreadNotifications} ${i18n.en.unreadNotifications}`}
                      className={cx([
                        'h-3 w-3 rounded-full bg-grassA-11 px-1 text-grass-1 backdrop-blur-xl',
                        data.unreadNotifications > 0 ? 'block' : 'hidden',
                      ])}
                    />
                  </div>
                  <BellIcon
                  // style={{ fill: 'currentColor', stroke: 'currentColor' }}
                  />
                </div>
              </IconButton>
            </NavigationTrigger>

            <NavigationContent>
              <Flex gap="3" direction="column" className="w-48">
                <Flex justify="center" align="center" minHeight="80px">
                  {data.unreadNotifications > 0 ? (
                    <ul>
                      {data.notifications
                        .filter((notification) => !notification.readAt)
                        .map((notification, index) => (
                          <li key={index}>
                            <Text
                              size="2"
                              as="span"
                              color="grass"
                              weight="bold"
                            >
                              {notification.title}
                            </Text>
                          </li>
                        ))}
                    </ul>
                  ) : (
                    <Text size="2" as="span" color="grass" weight="bold">
                      <Flex
                        direction="column"
                        justify="center"
                        align="center"
                        gap="1"
                      >
                        <InboxIcon />
                        {i18n.en.noNotifications}
                      </Flex>
                    </Text>
                  )}
                </Flex>
                <Separator size="4" />

                <Button asChild variant="soft" size="3" color="grass">
                  <Link href={i18n.links.notifications}>
                    <Text weight="bold">
                      {i18n.en.actions.viewAllNotifications}
                    </Text>
                  </Link>
                </Button>
              </Flex>
            </NavigationContent>
          </NavigationItem>

          <NavigationItem>
            <NavigationTrigger asChild>
              <IconButton variant="ghost" aria-label={i18n.en.labels.userNav}>
                {/* <Avatar
                  src={data.profile?.image}
                  alt={data.name}
                  size="3"
                  variant="soft"
                  fallback={<User2Icon />}
                /> */}
                <User2Icon
                // style={{ fill: 'currentColor', stroke: 'currentColor' }}
                />
              </IconButton>
            </NavigationTrigger>

            <NavigationContent>
              <ul className="flex w-48 flex-col gap-2">
                {authLinks.map((link) => (
                  <li key={link.href} className="flex w-full flex-col">
                    <Button asChild variant="soft" size="3">
                      <NavigationLink href={link.href} className="w-full">
                        <span className="flex items-center justify-center gap-2">
                          <Strong>{link.label}</Strong>
                        </span>
                      </NavigationLink>
                    </Button>
                  </li>
                ))}

                <li className="flex w-full flex-col">
                  <form
                    action={() => logout.mutate()}
                    className="flex w-full flex-col"
                  >
                    <Button type="submit" size="4" className="w-full">
                      <Text weight="bold">{i18n.en.actions.signout}</Text>
                    </Button>
                  </form>
                </li>
              </ul>
            </NavigationContent>
          </NavigationItem>
        </NavigationList>
        <NavigationViewport />
      </Navigation>
    </Flex>
  );
}
