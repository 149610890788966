export const domainName = 'hedge.run';
export const domain = `https://${domainName}`;

export const emails = {
  contact: `contact@${domainName}`,
  support: `support@${domainName}`,
  feedback: `feedback@${domainName}`,
  security: `security@${domainName}`,
  sales: `sales@${domainName}`,
  admin: `admin@${domainName}`,
  noReply: `no-reply@${domainName}`,
  bot: `bot@${domainName}`,
  assistant: `assistant@${domainName}`,
  concierge: `concierge@${domainName}`,
};
